import Vue from 'vue'
import store from '@/store/index.js'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
  'zh': require('./zh'), // 中文包
  'en': require('./en') // 英文包
}

// 统一放store管理，方便监控
let lan = sessionStorage.getItem('myLan') || 'zh'
store.commit('setLan', lan)
// console.log('语言包文件拿到的值', store.state.lan)
export default new VueI18n({
  locale: store.state.lan, // 默认显示中文
  messages,
  silentTranslationWarn: true // 去掉warm提示
})