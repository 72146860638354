<template>
  <div>
    <div class="header_empty"></div>
    <div class="header"
         :style="{left: pos_nav_left+'px'}">
      <div class="wrapper w">
        <div class="leftFilex">
          <div class="wrapper-left">
            <a href="javascript:;"
               @click="navName == 'index' ? '' : $router.push('/')">
              <img src="../assets/images/logo.png"
                   alt />
            </a>
          </div>
          <div class="wrapper-middle">
            <h5 :class="{zh: lan.name=='EN'}">{{$t('navTitle')}}</h5>
          </div>
        </div>

        <div class="wrapper-right">
          <ul>
            <!-- <li :class="{active: navName == item.navName}" v-for="(item, t) in list" :key="t"> -->
            <li :class="{active: ($route.meta.parentName||'').toLowerCase() == item.navName || ($route.name||'').toLowerCase() == item.navName, lan: item.lan}"
                v-for="(item, t) in list"
                :key="t">
              <a :href="item.url_link"
                 target="_bank"
                 v-if="item.outLink">{{item.name}}</a>
              <!-- <a
                class="lan"
                href="javascript:;"
                @click="changeLan(item)"
                v-else-if="item.lan"
              >{{item.name}}</a>-->
              <a class="lan"
                 href="javascript:;"
                 v-else-if="item.lan">{{item.name}}</a>
              <a href="javascript:;"
                 @click="linkFn(item)"
                 v-else>{{item.name}}</a>
              <!-- <router-link :to="item.url_link||''" v-else>{{item.name}}</router-link> -->
              <ul class="sub-menu"
                  v-if="item.children">
                <li v-for="(li,i) in item.children"
                    :key="t+'-'+i">
                  <a :href="li.url_link"
                     target="_bank"
                     v-if="li.outLink">{{li.name}}</a>
                  <a class="lan"
                     href="javascript:;"
                     @click="changeLan(li)"
                     v-else-if="item.lan">{{li.name}}</a>
                  <a href="javascript:;"
                     @click="linkFn(li)"
                     v-else>{{li.name}}</a>
                  <!-- <router-link :to="li.url_link||''" v-else>{{li.name}}</router-link> -->
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNav } from "@/api/public";
import { getLocationData } from "@/utils/utils";
export default {
  name: "Header",
  props: {
    msg: String
  },
  data () {
    return {
      navName: "",
      pos_nav_left: 0,
      title: "第四届中国国际茶叶博览会",
      list: [
        // {
        //   name: "首页",
        //   navName: "index",
        //   id: 0,
        //   url_link: "/"
        // }
      ],
      lan: {
        name: "EN",
        lan: true,
        url_link: "",
        children: [
          {
            name: "中文"
          },
          {
            name: "EN"
          }
        ]
      },
      getted: false,
      navDataDone: false,
      routeTime: null
    };
  },
  watch: {
    navDataDone (val) {
      if (!val) return;
      /* let bId = (
        this.list.filter(f => {
          return (
            (((f.children || [])[0] || {}).url_link || "").split("?")[0] ==
            this.$store.state.navName
          );
        })[0] || "index"
      ).banner_key;
      // let id = this.list.filter(f=>{})
      this.$store.commit("setBannerId", bId); */
    },
    $route (to, from) {
      clearTimeout(this.routeTime);
      // console.log("header监控路由改动", to, this.navName);
      this.routeTime = setTimeout(() => {
        if (to.name == "empty") {
          return;
        }
        this.getInfo();
        let navName = (
          (this.$route.meta || {}).parentName ||
          "" ||
          this.$route.name ||
          to.name ||
          "index"
        ).toLowerCase();
        this.$store.commit("setNavName", navName);
        // console.log('header，watch获取路由', to.name, from.name, navName, to)

        /* let bId = ( // bannerId，拿父级的
          this.list.filter(f => {
            return (
              (((f.children || [])[0] || {}).url_link || "")
                .split("?")[0]
                .toLowerCase() == navName
            );
          })[0] || "index"
        ).banner_key ||'';
        this.$store.commit("setBannerId", bId); */
        let navId = sessionStorage.getItem("setNavId") || "";
        let bId = "";
        this.list.map(m => {
          if (m.id == navId) bId = m.banner_key;
          if (m.children) {
            m.children.map(m2 => {
              if (m2.id == navId) bId = m2.banner_key;
            });
          }
        });
        this.$store.commit("setBannerId", sessionStorage.getItem('important_bId_custom') || bId);
        if (sessionStorage.getItem('important_bId_custom')) {
          sessionStorage.removeItem('important_bId_custom')
        }
        // console.log(3344, bId);

        // console.log("banner类型", navName);
        this.navName = to.name.toLowerCase();
        if (from.name && to.name.toLowerCase() == from.name.toLowerCase()) {
          // 更换子菜单
          // this.$router.go(0)
          // this.$router.push({
          //   path: "/empty"
          // });
        }
        window.scroll(0, 0);
      }, 30);
    }
  },
  methods: {
    linkFn (item) {
      this.$pubNavLink(item, this)
      // console.log("导航点击", this.$myJson(item), item.banner_key);
      /* this.$store.commit("setBannerId", item.banner_key || ""); // 存精确到子集的bannerId
      this.$store.commit("setNavId", item.id || ""); // 存点击id
      sessionStorage.setItem("setNavId", item.id || "");
      this.$store.commit("setBannerLink", item.url_link || ""); // 存精确到子集的url_link，详情页可以判断是否需要banner
      // 路由转跳
      this.$router.push(item.url_link).catch(err => err); */
    },
    changeLan (item) {
      // console.log("changeLan", this.$myJson(item), item.name == 'EN' ? 'en' : 'zh', sessionStorage.getItem('myLan'));
      // let t = this.list[this.list.length-1]
      // t.name = t.children[item.name == 'EN' ? 0 : 1].name
      let lan = this.$store.state.lan,
        nowLan = item.name == "EN" ? "en" : "zh";
      this.$store.commit("setLan", nowLan); // 此处提交没什么用，刷新状态会丢失
      if (sessionStorage.getItem("myLan") != nowLan) {
        sessionStorage.setItem("myLan", nowLan); // 当前语言存放本地sessionStorage
        setTimeout(() => {
          this.$router.go(0);
        }, 200);
      }
    },
    getInfo () {
      return new Promise((resolve, reject) => {
        if (this.getted) {
          // reject({getted: this.getted})
          return;
        }
        this.getted = true;
        let lan = (this.$store.state.lan = "");
        getNav().then(res => {
          if (res.code == 200) {
            let d = JSON.parse(JSON.stringify(this.list));
            d.push(...res.data, this.lan);
            // console.log(444, d);
            d.filter(f => !f.lan).map((m, i) => {
              if (m.url_link.indexOf("http") > -1) m.outLink = true;
              (m.children || []).map(li => {
                if (li.url_link.indexOf("http") > -1) li.outLink = true;
                if (!m.url_link || m.url_link == "#") m.url_link = li.url_link;
              });
              if (!m.navName) {
                m.navName = m.url_link.split("?")[0].toLowerCase() || "index";
                if (m.navName == "/" && i == 0) m.navName = "index";
              }
              this.navDataDone = true; // 标题数据处理完成
            });
            this.list = d;
            this.$store.commit("setNavList", d);
            resolve(res);
          } else {
            reject(res);
          }
          // console.log("菜单结构", JSON.parse(JSON.stringify(this.list)));
        });
      });
    },
    scrollFn () {
      let sl = document.body.scrollLeft || document.documentElement.scrollLeft;
      this.pos_nav_left = -Math.round(sl);
    }
  },
  async created () {
    this.getInfo();
    window.addEventListener("scroll", this.scrollFn, true);
    this.lan.name = this.lan.children[
      sessionStorage.getItem("myLan") == "zh" ? 1 : 0
    ].name;
  }
};
</script>

<style scoped lang="scss">
.header_empty {
  width: 100%;
  height: 100px;
}
.header {
  width: 100%;
  min-width: 1226px;
  height: 100px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}
.wrapper {
  height: 100px;
  // background: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-left {
  height: 100px;
  float: left;
  // background: forestgreen;
  img {
    float: left;
    display: block;
    margin-top: 10px;
    height: 83%;
  }
}
.w {
  width: auto !important;
}
.wrapper-middle {
  height: 100px;
  // background: gray;
  // position: relative;
  display: flex;
  align-items: center;
  flex: 2;
  h5 {
    // line-height: 100px;
    font-size: 18px;
    // position: absolute;
    // left: 17px;
    color: #424242;
    &.zh {
      font-size: 24px;
    }
  }
}
.leftFilex {
  padding-right: 50px;
  justify-content: center;
  align-items: center;
}
.wrapper-right {
  height: 100px;
  float: right;
  flex: inherit;
  // background: gold;
  > ul {
    height: 100px;
    float: right;
    display: flex;
    align-items: center;
    > li {
      position: relative;
      float: left;
      &.active > a {
        color: #0e9b32;
        &:after {
          display: block;
        }
      }
      &.lan a {
        white-space: nowrap;
      }

      > a {
        position: relative;
        text-decoration: none;
        display: block;
        // line-height: 100px;
        color: #434343;
        font-size: 18px;
        margin: 0 10px;
        height: 100px;
        display: flex;
        align-items: center;

        &:after {
          position: absolute;
          bottom: 0px;
          left: 0;
          content: "";
          width: 100%;
          height: 3px;
          background: #0e9b32;
          display: none;
        }
      }
      > ul {
        position: absolute;
        top: 100px;
        left: 50%;
        // width: 110px;
        padding: 10px 0;
        background: #fff;
        box-shadow: 0px 7px 14px 0px #666666;
        transform: translateX(-50%);
        z-index: 100;
        display: none;

        li {
          width: 100%;
          // height: 40px;
          font-size: 18px;
          // line-height: 40px;
          // text-align: center;
          a {
            padding: 16px 20px;
            color: #434343;
            text-decoration: none;
            display: block;
            line-height: 1.4em;
            white-space: nowrap;
          }

          &:hover {
            background: #0e9b32;

            a {
              color: #fff;
            }
          }
        }
      }
      &:hover {
        > ul {
          display: block;
        }
        > a {
          color: #0e9b32;

          &::after {
            display: block;
          }
        }
      }
    }
  }
}
</style>
