import store from '@/store/index'

export function setBannerType(num = 1) { // 设置banner类型，弃用了
  store.commit('bannerId', num)
}

export function getLocationData() { // 获取href的信息
  let json = {}
    (location.href.split('?')[1] || '').split('&') || [].map(m => {
      if (m.split['='][0]) json[m.split('=')[0]] = m.split('=')[1]
    })
  return json
}

// 公用仿导航条转跳
export default function pubNavLink(params, vue) {
  vue.$store.commit("setBannerId", params.banner_key || ""); // 存精确到子集的bannerId
  vue.$store.commit("setNavId", params.id || ""); // 存点击id
  sessionStorage.setItem("setNavId", params.id || "");
  vue.$store.commit("setBannerLink", params.url_link || ""); // 存精确到子集的url_link，详情页可以判断是否需要banner
  // 路由转跳
  vue.$router.push(params.url_link).catch(err => err);
}