<template>
  <div class="footer-wrapper">
    <div class="footer w">
      <div class="footer-top"></div>
      <div class="footer-middle">
        <div class="footer-middle-1">
          <img class="img" src="../assets/images/25.jpg" alt />
          <p>{{$t('foot.cbh')}}</p>
        </div>
        <div class="footer-middle-1">
          <img class="img" src="../assets/images/24.jpg" alt />
          <p>{{$t('foot.cbhImg')}}</p>
        </div>
        <div class="footer-middle-2">
          <h5>{{$t('foot.contact')}}</h5>
          <i>{{$t('foot.tel')}}&nbsp;:&nbsp;0571-82356656</i>
          <p>{{$t('foot.address')}}&nbsp;:&nbsp;{{$t('foot.addressDet')}}</p>
          <span>{{$t('foot.addressDet1')}}</span>
        </div>
        <div class="footer-middle-3">
          <h5>{{$t('foot.zbdw')}}&nbsp;</h5>
          <i>{{$t('foot.ncb')}}</i>
          <p>{{$t('foot.zjsrmzf')}}</p>
        </div>
        <div class="footer-middle-4">
          <h5>{{$t('foot.cbdw')}}</h5>
          <i>{{$t('foot.hzsrmzf')}}</i>
          <p>{{$t('foot.zjsnynct')}}</p>
          <span>{{$t('foot.zjsgxhzslhs')}}</span>
        </div>
      </div>
      <div class="footer-bottom">
        <i>{{$t('foot.bq')}} {{$t('foot.bqVal')}}</i>
        <span v-if="code">
          <a href="https://beian.miit.gov.cn/" target="_blank" >
            {{$t('foot.bah')}}&nbsp;:&nbsp;&nbsp;
            {{code}}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { webInfo } from "@/api/public.js";
export default {
  name: "Footer",
  data() {
    return {
      code: ""
    };
  },
  created() {
    webInfo({
      key: "record"
    }).then(res => {
      if (String(res.code) === "200") {
        this.code = res.data.value || "";
      }
    });
  }
};
</script>

<style scoped lang="scss">
.footer-wrapper {
  width: 100%;
  background: #333;
}
.footer-top {
  height: 44px;
}
.footer-middle {
  overflow: hidden;

  .footer-middle-1 {
    float: left;
    width: 140px;
    text-align:center;
    p {
      margin-top: 9px;
      color: #999999;
      font-size: 14px;
    }
    img{
      width: 80%;
      margin-left:0!important;
    }
  }

  .footer-middle-2 {
    float: left;
    width: 370px;

    h5 {
      font-size: 20px;
      font-weight: 400px;
      color: #fff;
      margin-top: 27px;
    }
    i {
      display: inline-block;
      color: #fff;
      margin-top: 6px;
    }
    p {
      color: #fff;
      margin-top: 18px;
    }
    span {
      display: inline-block;
      color: #fff;
      margin-top: 16px;
      margin-left: 45px;
    }
  }

  .footer-middle-3 {
    float: left;
    width: 250px;

    h5 {
      margin-top: 14px;
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }
    i {
      display: inline-block;
      color: #fff;
      margin-top: 16px;
    }
    p {
      color: #fff;
      margin-top: 16px;
    }
  }
  .footer-middle-4 {
    padding-left: 30px;
    float: left;
    width: 196px;

    h5 {
      color: #fff;
      font-size: 20px;
      margin-top: 14px;
    }
    i {
      display: inline-block;
      color: #fff;
      margin-top: 16px;
    }
    p {
      color: #fff;
      margin-top: 16px;
    }
    span {
      display: inline-block;
      color: #fff;
      margin-top: 16px;
    }
  }
}
.footer-bottom {
  i {
    display: inline-block;
    color: #fff;
    line-height: 116px;
    margin-left: 338px;
  }

  span {
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 116px;
    margin-left: 58px;
  }
  a{
    color:#fff;
    &:hover{
      text-decoration: underline;
    }
  }
}
.img {
  margin-left: 6px;
}
</style>
