<template>
  <div class="aboutx">
    <div class="title w clearfix">
      <h5>{{title}}</h5>
      <i>来源&nbsp;:</i>&nbsp;&nbsp;
      <img :src="media_logo"
           alt />
      <p class="p">{{media_name}}</p>
      <span>{{time}}</span>
      <p>浏览量：{{browse_num}}</p>
    </div>

    <div class="text w clearfix"
         v-html="content">

    </div>

  </div>
</template>

<script>
import { getNewsDetails } from "@/api/index";

export default {
  data () {
    return {
      newsId: 0,
      content: '',
      title: '',
      media_logo: '',
      media_name: '',
      browse_num: 0,
      time: ''
    }
  },
  methods: {
    info () {
      let query = this.$route.query;
      getNewsDetails({
        'newsId': query.id
      }).then(res => {
        if (res.code == '200') {
          let data = res.data;
          this.content = data.content;
          this.title = data.title,
            this.media_logo = data.media_logo,
            this.media_name = data.media_name,
            this.browse_num = data.browse_num,
            this.time = data.create_time
        } else {
          // alert(res.message | '')
        }
      })
    }
  },
  watch: {
    $route: {
      handler () {
        this.info()
      },
      deep: true
    }
  },
  created () {
    this.info()
  },
};
</script>

<style  scoped lang="scss">
.title {
  height: 70px;
  margin-top: 60px;
  position: relative;

  .p {
    margin-left: 20px;
  }
  img {
    position: absolute;
  }

  h5 {
    text-align: center;
    color: #565656;
    font-size: 24px;
    box-sizing: border-box;
    margin-bottom: 28px;
  }

  i {
    margin-left: 390px;
    color: #999999;
  }

  span {
    color: #999999;
    margin-left: 40px;
  }

  p {
    display: inline;
    color: #999999;
    margin-left: 40px;
  }
}
.text {
  margin-top: 40px;
  line-height: 32px;
  // background: brown;
  // height: 400px;

  p {
    line-height: 24px;
    margin-bottom: 12px;
    color: #333333;
  }
  img {
    margin-top: 20px;
  }
}
.wrap {
  margin-bottom: 80px;
  margin-top: 55px;

  h5 {
    text-align: center;
    font-weight: 400;
    color: #606060;
    line-height: 28px;
    font-size: 20px;
  }
  p {
    line-height: 24px;
    margin-bottom: 12px;
    color: #333333;
    line-height: 24px;
    margin-bottom: 12px;
    font-weight: 400;
  }

  img {
    margin-top: 35px;
  }
}
</style>