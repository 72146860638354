module.exports = {
    navTitle: 'The 4th China International Tea Expo',
    home: {
        title: '中国国际茶叶博览会',
        cloud: {
            information: 'Cloud News',
            hall: 'Cloud Exhibition Hall',
            signUp: 'Audience Sign-up',
            exhibitor: 'Exhibitor Sign-up',
            booking: 'Booth Booking ',
            activity: 'Concurrent Events',
            guide: 'Exhibitors Guide',
            news: 'Cloud News',
            images: 'Cloud Images',
            videos: 'Cloud Videos',
            Countdown: 'Countdown'
        },
        guide: { // 展商导览
            title: 'Enterprises taking part in the 4th China International Tea Expo',
            cont: 'The most authoritative, influential and largest world tea fair recognized by the world'
        }
    },
    tea: { // 茶文化
        culture: 'Tea Culture',
        knowledge: 'Tea Knowledge',
        international: 'International Tea',
        history: 'Tea History',
        life: 'Tea Life',
        achievements: 'Tea Achievements',
        image: 'Pictures&Texts',
        video: 'Videos'
    },
    news: { // 新闻管理
        exhibition: 'About Exhibition',
        pictures: 'Pictures',
        videos: 'Videos',
        source: 'source',
        views: 'Views',
        addTime: 'Add time'
    },
    exhibitors: {
        note: 'Exhibitors Notes',
        signUp: 'Sign-up',
        famous: 'Famous Enterprises',
        list: 'Exhibitors List',
        booking: 'Booth Booking',
        form: {
            es: 'Exhibition Session',
            cn: 'Company/Institute Name',
            name: 'Name',
            position: 'Title',
            sex: 'Gender',
            male: 'Male',
            female: 'Female',
            phone: 'Cell Phone ',
            c_phone: 'Company Phone',
            nature: 'Company Nature',
            at: 'Annual Turnover',
            email: 'email Address',
            area: 'Area',
            select: 'Please select',
            selectSex: 'Gender',
            province: 'Province',
            city: 'City',
            com_nature: 'Company Nature',
            addressDet: 'Address',
            brand: 'Brand',
            selectArea: 'Please select exhibition area',
            save: 'Save',
            forExhibitor: 'For Exhibitor',
            pavilion: 'Pavilion',
            p_area: 'Area',
            boothNo: 'Booth No.',
            select_bn: 'Please select booth no.',
            com_name: 'Company Name',
            select_cn: 'Please select company name',
            groupName: 'Exhibition Group Name',
            select_gn: 'Please select exhibition group name',
            district: 'District',
            exhibits: 'Exhibits',
            select_ex: 'Please select exhibit',
            P_C: 'Province/City',
            business_c: 'Business Category',
            operate: 'Operate',
            lookDet: 'Details',
            submit: 'Submit',
            picture: 'Pictures&Texts',
            video: 'Videos'
        },
        tips: {
            cc: 'Please select',
            name: 'Please input your name',
            sex: 'Please select gender',
            tel: 'Please input company phone',
            t_err: 'The phone format is incorrect',
            area: 'Please select area',
            address: 'Please input address',
            ci_name: 'Please input company/institute Name',
            max50: 'Enter up to 50 characters',
            phone: 'Please input phone number',
            p_err: 'Incorrect format of mobile phone number',
            email: 'Please input email',
            e_err: 'The mailbox format is incorrect'
        }
    },
    services: { // 观众服务
        signUp: 'Sign up',
        structure: 'Exhibits Structure',
        distribution: 'Hall Distribution',
        roadMap: 'Exhibition Roadmap',
        trafficInfo: 'Traffic Information'
    },
    live: { // 直播一条街
        tour: 'Exhibition Tour',
        tea: 'Tea Stories Telling',
        shows: 'Big Names Shows',
        shopping: 'Live-stream Shopping',
        tmallLive: 'Tmall Live',
        pTime: 'Playback times'
    },
    events: { // 同期活动
        major: 'Major Events',
        promotion: 'Promotion Events'
    },
    about: { // 关于我们
        us: 'About us',
        previous: 'Previous Exhibitions'
    },
    banner: {
        welcome: 'Welcome to the 4th China International Tea Expo!',
        time: '',
        time_end: 'Day(s) Left Until Opening Ceremony!'
    },
    foot: { // 底部
        cbh: 'China International Tea Expo',
        cbhImg: 'Cloud tea expo',
        contact: 'Contact Us',
        tel: 'Tel',
        address: 'Address',
        addressDet: 'Benjing Avenue 353, Xiaoshan District, Hangzhou, Zhejiang',
        addressDet1: 'Room 1205, Building B, Hangzhou International Expo Center',
        zbdw: 'Sponsors',
        ncb: "Ministry of Agriculture and Rural Affairs of the People's Republic of China",
        zjsrmzf: "The People's Government of Zhejiang Province",
        cbdw: 'Organizers',
        hzsrmzf: "The People's Government of Hangzhou Municipality",
        zjsnynct: 'Department of Agriculture and Rural Affairs of Zhejiang Province',
        zjsgxhzslhs: 'Zhejiang Provincial Supply and Marketing Cooperative',
        bq: 'All right reserved!',
        bqVal: 'China International Tea Expo Committee',
        bah: 'Record Filled No.'
    },
    others: { // 其他
        onLine: 'On-line Tea Fair',
        shoppingCar: 'Shopping Cart',
        orderBook: 'Book a Booth'
    }
}