import axios from 'axios'
// import { Message } from 'view-design'
import router from '@/router'
import store from '@/store/index.js'
// import { delUser, getUser } from '@/utils/session'

// const requestUrt = `${process.env.VUE_APP_URL}/`
// const baseUrl = 'http://192.192.100.30/hjc_web_admin/public/'
const baseUrl = 'https://hzcbhapi.china-teaexpo.com/'
    // 创建一个axios实例
const service = axios.create({
    baseURL: baseUrl,
    timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // if (store.state.isLogin) {
        //   config.headers.CRMAuthorization = getUser()
        // }
        // console.log('config', config)
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
            // console.log(res)
            // if (res.code == '401' || res.message == '登录已失效') {
            //   Message.error('登录信息失效，请重新登录')
            //   router.push('/login')
            //   delUser()
            //   store.commit('isLogin', false)
            //   return
            // }
        return res
    },
    error => {
        Message.error({
            content: error.message || '网络请求失败，请重试',
            duration: 5
        })
        return Promise.reject(error)
    }
)

const request = opt => {
    opt.method = opt.method ? opt.method : 'POST'
    if (opt.method === 'post') {
        // const token = getUser() ? getUser().token : ''
        // if (opt.data || opt.params) {
        //   opt.data = opt.data || opt.params
        //   if (!opt.data.token) {
        //     // 无token
        //     opt.data.token = token
        //   }
        // } else {
        //   opt.data = { token }
        // }
        opt.data = opt.data || opt.params
    }
    if (opt.method === 'get') {
        // axios 链接传参用params post数据用data
        // 此处做了兼容处理 可统一使用data
        opt.params = opt.data || opt.params
    }
    let urlParam = (opt.url || '').split('?')[1] || ''
    let lan = sessionStorage.getItem('myLan') || ''
    if (urlParam.indexOf('lang=') == -1) {
        opt.url = opt.url + (urlParam ? '&' : '?') + 'lang=' + (lan == 'zh' || !lan ? 'zh-cn' : 'en-us')
    }
    return service(opt)
}

export default request