module.exports = {
    navTitle: '第四届中国国际茶叶博览会',
    home: {
        title: '首页',
        cloud: {
            information: '云资讯',
            hall: '云展厅',
            signUp: '观众报名',
            exhibitor: '展商报名',
            booking: '订展意向',
            activity: '同期活动',
            guide: '展商导览',
            news: '云新闻',
            images: '云图像',
            videos: '云视频',
            Countdown: '倒计时'
        },
        guide: { // 展商导览
            title: '第四届中国国际茶叶博览会企业',
            cont: '国内外公认的最具权威、最具规模、最具影响力的世界茶叶盛会'
        }
    },
    tea: { // 茶文化
        culture: '茶文化',
        knowledge: '茶知识',
        international: '国际茶',
        history: '茶历史',
        life: '茶生活',
        achievements: '茶成就',
        image: '图文',
        video: '视频'
    },
    news: { // 新闻管理
        exhibition: '展会新闻',
        pictures: '图片新闻',
        videos: '视频新闻',
        source: '来源',
        views: '浏览量',
        addTime: '添加时间'
    },
    exhibitors: {
        note: '展商须知',
        signUp: '展商报名',
        famous: '名企风采',
        list: '展商列表',
        booking: '订展意向',
        form: {
            es: '参展场次',
            cn: '公司/机构名称',
            name: '姓名',
            position: '职位',
            sex: '性别',
            male: '男',
            female: '女',
            phone: '手机号',
            c_phone: '单位电话',
            nature: '公司性质',
            at: '年度营业额',
            email: '邮箱地址',
            area: '地区',
            select: '请选择',
            selectSex: '请选择性别',
            province: '选择省',
            city: '选择市',
            com_nature: '请选择公司性质',
            addressDet: '详细地址',
            brand: '请选择主营品类',
            selectArea: '请选择品类',
            save: '保存',
            forExhibitor: '展商筛选',
            pavilion: '展馆',
            p_area: '展区',
            boothNo: '展位号',
            select_bn: '请选择展位号',
            com_name: '公司名称',
            select_cn: '请选择公司名称',
            groupName: '展团名称',
            select_gn: '请选择展团名称',
            district: '地区',
            exhibits: '展品',
            select_ex: '请选择展品',
            P_C: '省市',
            business_c: '经营品类',
            operate: '操作',
            lookDet: '查看详情',
            submit: '提交',
            picture: '图文',
            video: '视频'
        },
        tips: {
            cc: '请选择场次',
            name: '请输入姓名',
            sex: '请选择性别',
            tel: '请输入电话',
            t_err: '电话格式不正确',
            area: '请选择地区',
            address: '请输入详细地址',
            ci_name: '请输入公司/机构名称',
            max50: '最多输入50个字符',
            phone: '请输入手机号',
            p_err: '手机号格式不正确',
            email: '请输入邮箱',
            e_err: '邮箱格式不正确'
        }
    },
    services: { // 观众服务
        signUp: '观众报名',
        structure: '展品结构',
        distribution: '展馆分布',
        roadMap: '展会地图',
        trafficInfo: '交通信息'
    },
    live: { // 直播一条街
        tour: '逛展导览',
        tea: '云播茶事',
        shows: '大咖讲茶',
        shopping: '直播购物',
        tmallLive: '天猫直播',
        pTime: '播放次数'
    },
    events: { // 同期活动
        major: '重大活动',
        promotion: '推介活动'
    },
    about: { // 关于我们
        us: '关于我们',
        previous: '往届展会'
    },
    banner: {
        welcome: '欢迎参加第四届中国国际茶叶博览会！',
        time: '距离开幕还有',
        time_end: '天'
    },
    foot: { // 底部
        cbh: '中国国际茶叶博览会',
        cbhImg: '云上茶博会',
        contact: '联系我们',
        tel: '电话',
        address: '地址',
        addressDet: '浙江省杭州市萧山区奔竞大道353号',
        addressDet1: '杭州国际博览中心B座1205办公室',
        zbdw: '主办单位',
        ncb: '中华人民共和国农业农村部',
        zjsrmzf: '浙江省人民政府',
        cbdw: '承办单位',
        hzsrmzf: '杭州市人民政府',
        zjsnynct: '浙江省农业农村厅',
        zjsgxhzslhs: '浙江省供销合作社联合社',
        bq: '版权所有',
        bqVal: '中国国际茶叶博览会组委会',
        bah: '备案号'
    },
    others: { // 其他
        onLine: '网上茶博',
        shoppingCar: '购物车',
        orderBook: '预订展位'
    }
}