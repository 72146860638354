import Vue from 'vue'
import Vuex from 'vuex'
import {
  stat
} from 'fs';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bannerId: 1, // 1=往会新闻（默认）；2=往届展会；3=会议活动；4=直播一条街；5=茶文化；6=展商列表；7=关于我们；8=''
    bannerLink: '', // 当前bannerId下的url_link
    navName: 'index', // 模块名称
    navId: '', // 导航点击的id，分父子级
    lan: 'zh', // 当前语言，zh=中文 en=英文
    navList: [] // 导航条菜单信息
  },
  mutations: {
    setBannerId(state, num) { // banner来源
      state.bannerId = num
    },
    setBannerLink(state, str) { // banner下的href
      state.bannerLink = str
    },
    setNavName(state, str) { // 导航条模块名
      state.navName = str
    },
    setNavId(state, val) {
      state.navId = val
    },
    setLan(state, val) {
      state.lan = val
    },
    setNavList(state, arr) {
      state.navList = arr || []
    }
  },
  getters: {
    navItem(state, getters) { // 筛选出转跳目标的nav参数
      // 传入一个对象{key: '', value: ''}，key是要查找对象的key值，如不存在key就默认是url_link，value是要查找对象key的value，返回外界一个对象
      return obj => {
        if (obj.key == undefined) {
          obj.key = 'url_link'
        }
        // console.log('getters.navItem', obj)
        let res
        state.navList.map(m => {
          if (!res && m.children) {
            m.children.map(chi => {
              if (chi[obj.key] && chi[obj.key].indexOf(obj.value) > -1) {
                res = chi
              }
            })
          }
          if (!res && m[obj.key] && m[obj.key].indexOf(obj.value) > -1) {
            res = m
          }
        })
        return res || ''
      }
    }
  },
  actions: {},
  modules: {}
})