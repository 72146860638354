import request from '@/utils/request'

// banner图
export function bannerList (params) {
  return request({
    url: 'api/banner/get_list',
    method: 'post',
    params
  })
}

// 头部菜单
export function getNav (key) {
  return request({
    url: `api/top_menu/get_list/`,
    method: 'post'
  })
}

// 基础设置
export function webInfo (params) {
  return request({
    url: 'api/basics_set_up/get_info',
    method: 'post',
    params
  })
}