<template>
  <div id="app">
    <Header></Header>
    <!-- <transition name="fade" mode="in-out"> -->
    <Banner></Banner>
    <!-- </transition> -->
    <transition name="fade" mode="in-out">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Banner from "@/components/banner";
import Footer from "@/components/Footer";
export default {
  name: "APP",
  components: {
    Header,
    Banner,
    Footer
  }
};
</script>

<style lang="scss">
.fade-enter {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-enter-active {
  transition: opacity 0.1s;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
  color: #1ADE4B!important;
}
// @media (max-width:1500px){
//   .wrapper-middle{
//     h5{
//       padding-left:20px;
//     }
//   }
// }
// @media (max-width:1030px){
//   .w{
//     width:74%!important;
//   }
// }
// @media (max-width:980px){
//   .w{
//     width:80%!important;
//   }
// }
// @media (max-width:890px){
//   .w{
//     width:84%!important;
//   }
// }
@media (max-width:1400px){
  // .wrapper-left{
  //   img{
  //     height:83%;
  //   }
  // }
  // .wrapper-middle{
  //   width:190px!important;
  //   h5{
  //     padding-left:10px;
  //   }
  // }
  // .w{
  //   width:66%;
  // }
//   .wrapper-middle{
//     width:auto!important;
//   }
//   .wrapper-right{
//     ul{
//       li{
//         a{
//           font-size:14px!important;
//         }
//       }
//     }
//   }
//   .w{
//     width:100vw!important;
//   }
//   .nav-image{
//     width:100vw!important;
//     .overs{
//       width:84vw!important;
//     }
//   }
//   .tea{
//     display:flex;
//     .tea-left{
//       flex:1;
//       margin-right:10px;
//     }
//     .tea-right{
//       flex:2;
//     }
//   }
//   .three{
//     ul{
//       li{
//         width:27%!important;
//       }
//     }
//   }
//   .firstpage{
//     .icon{
//       overflow:hidden;
//       ul{
//         display:block!important;
//         li{
//           width:14% !important;
//         }
//       }
//     }
//   }
//   .nav1,.nav{
//     p{
//       padding-left:0!important;
//       display:block!important;
//     }
//     a{
//       padding-left:0!important;
//       float:right;
//     }
//   }
//   .footer-middle-2,
//   .footer-middle-3,
//   .footer-middle-4{
//     width:auto!important;
//     padding-right:7%;
//     font-size:12px;
//   }
//   .footer{
//     width:100%!important;
//   }
//   .five{
//     width:100vw!important;
//     li{
//       width:15%!important;
//       div{
//         width:100%!important;
//         height:100%!important;
//       }
//     }
//   }
}
body {
overflow-x:hidden;
margin:0 auto;
}
</style>
