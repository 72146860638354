import Vue from 'vue'
import VueRouter from 'vue-router'
import Zhiboone from '../views/Zhiboone.vue'
import Newsa from '../views/Newsa.vue'
import NewList from '@/components/newList.vue'
import newDetails from '../views/newDetails.vue'



Vue.use(VueRouter)

// 说明：
// example = {
//   meta: {
//     title: '页面标题',
//     parentName: '父模块的name，非一级目录可用于判断导航条的active，可忽略大小写'
//   }
// }
const routes = [{
        path: '/',
        name: 'Index',
        meta: {
            title: '首页'
        },
        component: () =>
            import ('../views/Index.vue'),
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title: '关于我们'
        },
        component: () =>
            import ('../views/About.vue')
    },
    {
        path: '/aboutDet',
        name: 'AboutDet',
        meta: {
            title: '公司简介',
            hideBanner: true // 隐藏banner
        },
        component: () =>
            import ('../views/AboutDet.vue')
    },
    {
        path: '/yunimage',
        name: 'Yunimage',
        component: () =>
            import ('../views/Yunimage.vue')
    },

    // {
    //   path: '/news',
    //   name: 'News',
    //   component: () => import('../views/News.vue')
    // },
    {
        path: '/New',
        name: 'New',
        meta: {
            title: '新闻管理'
        },
        component: () =>
            import ('../views/New.vue')
    },
    {
        path: '/newDetails',
        name: 'newDetails',
        meta: {
            parentName: 'new',
            hideBanner: true
        },
        component: () =>
            import ('../views/newDetails.vue')
    },
    {
        path: '/Exhibitors',
        name: 'Exhibitors',
        meta: {
            title: '参展企业'
        },
        component: () =>
            import ('../views/Exhibitors.vue')
    },
    {
        path: '/BrandPresence',
        name: 'BrandPresence',
        meta: {
            title: '企业风采',
            parentName: 'Exhibitors'
        },
        component: () =>
            import ('../views/BrandPresence.vue')
    },
    {
        path: '/instructions',
        name: 'instructions',
        meta: {
            title: '参展须知',
            parentName: 'Exhibitors'
        },
        component: () =>
            import ('../views/instructions.vue')
    },
    {
        path: '/reserveForm',
        name: 'reserveForm',
        meta: {
            title: '订展意向',
            parentName: 'Exhibitors'
        },
        component: () =>
            import ('../views/reserveForm.vue')
    },
    {
        path: '/streamf',
        name: 'Streamf',
        meta: {
            title: '参展商品'
        },
        component: () =>
            import ('../views/ListGoods.vue')
    },
    {
        path: '/tea',
        name: 'Tea',
        meta: {
            title: '茶文化'
        },
        component: () =>
            import ('../views/Tea.vue')
    },
    {
        path: '/TeaDet',
        name: 'TeaDet',
        meta: {
            title: '茶文化',
            parentName: 'Tea',
            hideBanner: true
        },
        component: () =>
            import ('../views/TeaDet.vue')
    },
    {
        path: '/zhibo',
        name: 'Zhibo',
        meta: {
            title: '直播',
        },
        component: () =>
            import ('../views/Zhibo.vue')
    },
    {
        path: '/zhiboone',
        name: 'Zhiboone',
        meta: {
            title: '直播详情',
            parentName: 'zhibo',
            hideBanner: true
        },
        component: Zhiboone
    },
    {
        path: '/service',
        name: 'Service',
        meta: {
            title: '观众服务'
        },
        component: () =>
            import ('../views/Service.vue')
    },
    // {
    //   path: '/newsa',
    //   name: 'Newsa',
    //   component: Newsa
    // },
    {
        path: '/newsa',
        name: 'Newsa',
        meta: {
            parentName: 'new',
            hideBanner: true
        },
        component: Newsa
    },
    {
        path: '/newList',
        name: 'NewList',
        meta: {
            // parentName: 'new',
            hideBanner: true
        },
        component: NewList
    },
    {
        path: '/Activity',
        name: 'Activity',
        meta: {
            title: '观众服务'
        },
        component: () =>
            import ('../views/Activity.vue')
    },
    {
        path: '/empty',
        name: 'empty',
        meta: {
            title: '',
            hideBanner: true
        },
        component: () =>
            import ('@/components/empty.vue')
    }
]

const router = new VueRouter({
    // mode: 'history',
    routes
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router