<template>
  <div class="yunvideo">
    <!-- 容器 -->
    <div class="wrap w clearfix">
      <h5>{{dataVideoData.title}}</h5>
      <i>{{dataVideoData.time}}</i>
      <span>{{$t('live.pTime')}}：{{dataVideoData.play_num}}</span>
      <div v-if="playOfs.indexOf('m3u8')>-1">
        <video id="my-video"
               class="video-js vjs-default-skin videos_medis"
               controls
               :src="urls"
               preload="auto"
               width="500px">
          <source :src="urls"
                  type="application/x-mpegURL" />
        </video>
      </div>
      <div v-if="playOfs.indexOf('mp4')>-1">
        <video class="video-js vjs-default-skin videos_medis"
               :src="dataVideoData.play_status===2 ? dataVideoData.push_flow_url : dataVideoData.play_url"
               width="500px"
               controls
               autoplay="autoplay">
        </video>
      </div>
      <!-- <div class="kuai"><img src="../assets/images/video.png" alt=""></div> -->
    </div>
  </div>
</template>

<script>
import { getLiveDetails } from "@/api/index";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  components: {},
  data () {
    return {
      dataVideoData: [],
      playOfs: "",
      tvPlayUrl: '',
      urls: ''
    };
  },
  watch: {
    $route: {
      handler () {
        this.initVideos(); // 视频video
      },
      deep: true
    }
  },
  created () {
    this.initVideos(); // 视频video
  },
  mounted () {

  },
  methods: {
    initVideos () {
      const datas = {
        liveConfigId: Number(this.$route.query.id)
      };
      getLiveDetails(datas).then(res => {
        this.dataVideoData = res.data;
        const plays =
          this.dataVideoData.play_status === 2
            ? this.dataVideoData.push_flow_url
            : this.dataVideoData.play_url;
        // const fileExtension = plays.substring(plays.lastIndexOf(".") + 1);
        this.playOfs = plays;

        this.tvPlayUrl = plays; //给source的src赋值
        this.urls = plays

        setTimeout(() => {
          videojs("my-video", {
            bigPlayButton: false,
            textTrackDisplay: false,
            posterImage: true,
            errorDisplay: false,
            controlBar: true
          }, function () {
            this.play();
          });
        }, 1000)
      });
    }
  }
};
</script>

<style scoped  lang="scss">
.videos_medis {
  width: 100%;
  height: 675px;
  padding-bottom: 40px;
  text-align: center;
  object-fit: contain;
  ::v-deep {
    video {
      height: 100%;
    }
  }
  &:focus {
    outline: none;
  }
}
.wrap {
  margin-top: 55px;
  // height: 1000px;
  // background: brown;
  position: relative;

  .kuai {
    // width: 1200px;
    // height: 701px;
    // background: #333333;
    // opacity: 0.73;
    // position: absolute;
    // top: 105px;
    // left: 0px;

    // img{
    //     display: block;
    //     margin: 0 auto;
    //     position: absolute;
    //     top: 330px;
    //     left: 580px;

    // }
  }

  h5 {
    font-weight: 500;
    color: #606060;
    font-size: 24px;
    text-align: center;
  }

  i {
    display: inline-block;
    font-weight: 400;
    color: #999999;
    margin-left: 470px;
  }

  span {
    display: inline-block;
    font-weight: 400;
    color: #999999;
    margin-left: 50px;
    padding-top: 27px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }

  img {
    margin-bottom: 100px;
  }
}
</style>