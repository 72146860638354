<template>
  <!-- banner -->
  <div class="banner">
    <transition name="el-zoom-in-top">
      <template v-if="!hideBanner">
        <div class="swiper-container"
             v-if="list.length > 0">
          <div class="swiper-wrapper">
            <el-image class="swiper-slide"
                      v-for="(item, i) in list"
                      :key="i"
                      :src="item.image || ''"
                      :alt="item.name||''"
                      @click="clickFn(item)"></el-image>
          </div>
          <!-- banner图字 -->
          <div class="word"
               v-if="$store.state.navName == 'index' && times.value">
            <h5>
              <template v-if="$i18n.locale=='zh'">{{$t('banner.welcome')}}</template>
              {{$t('banner.time')}}
              <span>&nbsp;{{times.value}}&nbsp;</span>
              {{$t('banner.time_end')}}
            </h5>
          </div>
          <!-- 轮播图按钮 -->
          <template v-if="list.length > 1">
            <!-- <div class="banner-btn-left banner-btn" @click="navleft">
              <a href="javascript:;">
                <img src="../assets/images/left.png" alt />
              </a>
            </div>
            <div class="banner-btn-right banner-btn" @click="navright">
              <a href="javascript:;">
                <img src="../assets/images/right.png" alt />
              </a>
            </div> -->
            <!-- 如果需要导航按钮 -->
            <!-- <div class="swiper-button-prev swiper-button-white"></div> -->
            <!-- <div class="swiper-button-next swiper-button-white"></div> -->
          </template>
        </div>
      </template>
    </transition>
    <!-- <div class="swiperHeight" :style="{height: swiperHeight + 'px'}" v-show="list.length == 0"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper";
import { bannerList, webInfo } from "@/api/public";
export default {
  name: "Banner",
  // props: {
  //   data: {
  //     type: Array,
  //     default() {
  //       return [];
  //     }
  //   }
  // },
  data () {
    return {
      times: {},
      list: [],
      swiper: null,
      swiperHeight: 0,
      firstBannerLoad: false, // 第一次加载banner后
      bannerIdNow: "", // 有效的bannerId
      hideBanner: false, // 非列表页，true时不需要banner
      timer: 0
    };
  },
  computed: {
    bannerId () {
      let id = this.$store.state.bannerId;
      if (String(id) == "0" || String(id) == "undefined") {
        id = "";
      }
      return id;
    }
  },
  watch: {
    bannerId: {
      handler (val) {
        if (val) this.bannerIdNow = val;
        // console.log("banner改变了", val);
        this.getData();
      },
      immediate: true
    },
    $route (to, from) {
      // this.getData();
      this.getTime();
      // console.log("路由变化了", to, to.query);
      // 详情页不显示banner
      this.hideBanner = to.meta.hideBanner ? true : false;
      /* if (!to.query.type && to.name.toLowerCase() != "index") {
        this.hideBanner = true;
      } else {
        this.hideBanner = false;
      } */
      if (this.$route.name == 'Zhibo') {
        if (this.$route.query.type == '4') {
          this.getDataFn();
        }
      }
    }
  },
  methods: {
    navleft () {
      this.swiper.slidePrev();
    },
    navright () {
      this.swiper.slideNext();
    },
    swiperFn () {
      if (this.swiper) {
        // this.swiper.update()
        // return
      }
      this.swiper = new Swiper(".swiper-container", {
        loop: this.list.length > 1 ? true : false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 如果需要前进后退按钮
        // nextButton: ".swiper-button-next",
        // prevButton: ".swiper-button-prev",
        // autoplay: this.list.length > 1 ? 5000 : 0,
        // autoplay: 3000,
        // speed: 700,
        //用户操作之后是否停止自动轮播默认true
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        //             loop: true,
        observer: true,
        observeParents: true,
        autoplay: 3000
      });
      setTimeout(() => {
        this.swiperHeight = this.swiper.height;
      }, 10);
    },
    getData () {
      // console.log(
      //   "banner的getData模块数据",
      //   this.bannerId
      // );
      if (!this.bannerId || this.bannerId == "") {
        this.list = [];
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getDataFn();
      }, 100);

      // 因监控状态，这里只取短时间内第一次运行的数据
      // if (this.timer) {
      //   return
      // }
      // this.timer = true
      // setTimeout(()=>{
      //   this.timer = false
      // },100)
      // this.getDataFn()
    },
    getDataFn () {
      bannerList({
        banner_cate_id: this.bannerIdNow
      }).then(res => {
        this.firstBannerLoad = true;
        if (String(res.code) === "200") {
          this.list = [];
          setTimeout(() => {
            this.list = res.data;
          }, 10);
          setTimeout(() => {
            this.swiperFn();
          }, 50);
        }
      });
    },
    getTime () {
      // 获取倒计时天数
      webInfo({
        key: "count_down"
      }).then(res => {
        if (res.code.toString() == "200") {
          this.times = res.data;
        }
      });
    },
    clickFn (item) {
      // 图片转跳
      if (item.link && item.link != "#") {
        if (item.link.indexOf("http") > -1) {
          window.open(item.link);
        } else {
          this.$router.push(item.link);
        }
      }
    },
    listenRelash (e) {
      // console.log('页面刷新', e)
      if (e) {
        if (this.hideBanner) {
          this.bannerIdNow = "";
        }
        sessionStorage.setItem("custom_bId_custom", this.bannerIdNow); // 存当前有效值
        // console.log("存数据", this.bannerIdNow);
      }
    }
  },
  mounted () {
    this.listenRelash();
    window.addEventListener("beforeunload", e => this.listenRelash(e));
  },
  destroyed () {
    window.removeEventListener("beforeunload", e => this.listenRelash(e));
  },
  created () {
    if (this.$route.meta.hideBanner)
      this.hideBanner = this.$route.meta.hideBanner;
    let bId =
      sessionStorage.getItem("important_bId_custom") || // 其他地方 来的，优先值高
      sessionStorage.getItem("custom_bId_custom") ||
      "";
    if (this.hideBanner) {
      this.bannerIdNow = "";
      bId = "";
    }
    // console.log("页面渲染，取数据", bId, "======", this.bannerIdNow);
    if (bId) {
      this.$store.commit("setBannerId", bId);
      sessionStorage.removeItem("custom_bId_custom");
    }
    if (sessionStorage.getItem("important_bId_custom")) {
      sessionStorage.removeItem("important_bId_custom");
    }
    this.getData();
    this.getTime();
  }
};
</script>

<style scoped  lang="scss">
.swiper-container {
  // height: 500px;
  width: 100%;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      background-color: #42b983;
      text-align: center;
      // line-height: 500px;
      height: 100%;
      width: 100%;
      // background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: cover;
    }
  }
  ::v-deep {
    .swiper-button-next,
    .swiper-button-prev {
      background-size: 50px 70px !important;
      width: 50px;
      height: 70px;
      margin-top: -40px;
    }
    .swiper-button-prev {
      left: 30px;
    }
    .swiper-button-next {
      right: 30px;
    }
  }
}

.banner {
  width: 100%;
  // height: 500px;
  // background: url("../assets/images/81.png") no-repeat;
  background-size: 100% 100%;

  .word {
    width: 100%;
    height: 100px;
    // opacity: 0.5;
    background: rgba(32, 141, 59, 0.5);

    position: absolute;
    bottom: 0;
    z-index: 100;

    h5 {
      font-size: 30px;
      color: #fff;
      text-align: center;
      line-height: 100px;

      span {
        position: relative;
        top: 2px;
        font-size: 50px;
      }
    }
  }

  .banner-btn {
    position: absolute;
    top: 50%;
    z-index: 100;
    width: 70px;
    height: 70px;
    margin-top: -35px;
    text-align: center;
    line-height: 70px;
    background: rgba(51, 51, 51, 0);
    transition: all 0.1s;

    img {
      vertical-align: middle;
    }

    &:hover {
      background: rgba(51, 51, 51, 0.5);
    }

    &.banner-btn-left {
      left: 100px;
    }
    &.banner-btn-right {
      right: 100px;
    }
  }
}
.el-image__inner {
  position: initial !important;
  top: auto !important;
  left: auto !important;
  -webkit-transform: translate(auto, auto) !important;
  transform: translate(auto, auto) !important;
  display: initial !important;
}
</style>