import request from '@/utils/request'

// 新闻
export function getIndexData(params) {
    return request({
        url: '/api/getNewsList',
        method: 'get',
        params
    })
}

// banner图
export function bannerList(params) {
    return request({
        url: '/api/banner/get_list',
        method: 'post',
        params
    })
}

// 新闻详情
export function getNewsDetails(params) {
    return request({
        url: '/api/getNewsDetails',
        method: 'get',
        params
    })
}

// 直播列表
export function getLiveList(params) {
    return request({
        url: '/api/getLiveList',
        method: 'get',
        params
    })
}

// 直播一条街-详情
export function getLiveDetails(params) {
    return request({
        url: '/api/getLiveDetails',
        method: 'get',
        params
    })
}

// 茶文化列表
export function getTeaCultureList(params) {
    return request({
        url: '/api/getCultureList',
        method: 'get',
        params
    })
}

// 茶文化详情
export function getCultureDetails(params) {
    return request({
        url: '/api/getCultureDetails',
        method: 'get',
        params
    })
}

// 云图像
export function cloudImg(params) {
    return request({
        url: '/api/brand_presence/indexImage',
        method: 'post',
        params
    })
}

// 云视频
export function cloudVideo(params) {
    return request({
        url: '/api/brand_presence/indexVideo',
        method: 'post',
        params
    })
}

// 茶文化分类列表
export function getCultureCateList(params) {
    return request({
        url: '/api/getCultureCateList',
        method: 'get',
        params
    })
}

//云新闻
export function getCloudNews(params) {
    return request({
        url: '/api/getCloudNews',
        method: 'get',
        params
    })
}

// 云资讯
export function getCloudNotice(params) {
    return request({
        url: '/api/notice/get_list',
        method: 'post',
        params
    })
}

// 往届展会
export function getGreviousExh(params) {
    return request({
        url: '/api/previous_event/list',
        method: 'post',
        params
    })
}

// 基础信息查询
export function getCloudNoticeListData(params) {
    return request({
        url: '/api/basics_set_up/get_info',
        // url: '/api/notice/get_info',
        method: 'post',
        params
    })
}

// 公用新闻信息查询
export function getNoticeInfo(params) {
    return request({
        url: '/api/notice/get_info',
        method: 'post',
        params
    })
}


/**
 * 展商
 */

//展商列表
export function exhibitorList(params) {
    return request({
        url: 'api/exhibitor/list',
        method: 'post',
        params
    })
}

//展商列表分类
export function exhibitorCate(params) {
    return request({
        url: 'api/exhibitor/cate',
        method: 'post',
        params
    })
}

// 往届展会详情
export function getGreviousExhInfo(params) {
    return request({
        url: '/api/previous_event/info',
        method: 'post',
        params
    })
}

// 展商须知
export function brandExhibitor(params) {
    return request({
        url: '/api/exhibitor/brandExhibitor',
        method: 'post',
        params
    })
}

// 企业风采
export function bandVideoimage(params) {
    return request({
        url: '/api/brand_presence/image',
        method: 'post',
        params
    })
}

// 定展
export function overIntentionGet(params) {
    return request({
        url: '/api/booth/list',
        method: 'post',
        params
    })
}


// 定展add
export function WillShowGet(params) {
    return request({
        url: '/api/booth/create',
        method: 'post',
        params
    })
}
// listvideo
export function videoListImg(params) {
    return request({
        url: '/api/brand_presence/video',
        method: 'post',
        params
    })
}

// list
export function ProductList(params) {
    return request({
        url: '/api/product/list',
        method: 'post',
        params
    })
}
// list详情
export function ProductListContent(params) {
    return request({
        url: '/api/exhibitor/info',
        method: 'post',
        params
    })
}
// 首页版本video
export function getMediaListVideoImg(params) {
    return request({
        url: '/api/getMediaList',
        method: 'get',
        params
    })
}