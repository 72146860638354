<template>
  <div class="aboutx">
    <div class="title w clearfix">
      <h5>{{title}}</h5>
      <p>{{$t('news.addTime')}}：{{time}}</p>
    </div>

    <!-- 文字 -->
    <div class="text w clearfix contentList"
         v-html="content"></div>
  </div>
</template>

<script>
import { getNoticeInfo } from "@/api/index";

export default {
  data () {
    return {
      newsId: 0,
      content: "",
      title: "",
      time: ""
    };
  },
  watch: {
    $route: {
      handler () {

        this.inifContent()
      },
      deep: true
    }
  },
  methods: {
    inifContent () {
      const datas = {
        id: this.$route.query
      };
      getNoticeInfo(datas).then(res => {
        this.time = res.data.create_time;
        this.title = res.data.title;
        this.content = res.data.content;
      });
    }
  },
  created () {
    this.inifContent()
  }
};
</script>

<style  scoped lang="scss">
.title {
  height: 70px;
  // width: 1200px;
  // background: burlywood;
  margin-top: 60px;
  text-align: center;
  position: relative;

  .p {
    margin-left: 20px;
  }
  img {
    position: absolute;
  }

  h5 {
    text-align: center;
    color: #565656;
    font-size: 24px;
    box-sizing: border-box;
    margin-bottom: 28px;
  }

  i {
    margin-left: 390px;
    color: #999999;
  }

  span {
    color: #999999;
    margin-left: 40px;
  }

  p {
    display: inline;
    color: #999999;
    // margin-left: 40px;
  }
}
.text {
  margin-top: 40px;
  padding: 30px;
  // background: brown;
  // height: 400px;

  p {
    line-height: 24px;
    margin-bottom: 12px;
    color: #333333;
  }
  img {
    margin-top: 20px;
  }
}
.wrap {
  margin-bottom: 80px;
  margin-top: 55px;

  h5 {
    text-align: center;
    font-weight: 400;
    color: #606060;
    line-height: 28px;
    font-size: 20px;
  }
  p {
    line-height: 24px;
    margin-bottom: 12px;
    color: #333333;
    line-height: 24px;
    margin-bottom: 12px;
    font-weight: 400;
  }

  img {
    margin-top: 35px;
  }
}
.contentList {
  padding: 30px;
  /deep/ p {
    margin: 1em auto;
  }
}
</style>