<template>
  <div class="aboutx">
    <div class="title w clearfix">
      <h5>{{title}}</h5>
      <i>{{$t('news.source')}}&nbsp;:</i>&nbsp;&nbsp;
      <img :src="media_logo"
           alt />
      <p class="p">{{media_name}}</p>
      <span>{{time}}</span>
      <p>{{$t('news.views')}}：{{browse_num}}</p>
    </div>

    <!-- 文字 -->
    <div class="text w clearfix"
         v-html="content"></div>
  </div>
</template>

<script>
import { getNewsDetails } from "@/api/index";

export default {
  data () {
    return {
      newsId: 0,
      content: '',
      title: '',
      media_logo: '',
      media_name: '',
      browse_num: 0,
      time: ''
    }
  },
  watch: {
    $route: {
      handler () {
        this.initTable();
      },
      deep: true
    }
  },
  methods: {
    initTable () {
      let query = this.$route.query;
      getNewsDetails({
        'newsId': query.id
      }).then(res => {
        if (res.code == '200') {
          let data = res.data;
          this.content = data.content;
          this.title = data.title,
            this.media_logo = data.media_logo,
            this.media_name = data.media_name,
            this.browse_num = data.browse_num,
            this.time = data.create_time
        }
      })
    }
  },
  created () {
    this.initTable();
  },
};
</script>

<style scoped lang="scss">
.clearfix {
  line-height: 30px;
}
.title {
  height: 70px;
  // width: 1200px;
  // background: burlywood;
  margin-top: 60px;
  position: relative;

  .p {
    margin-left: 20px;
  }
  img {
    position: absolute;
    width: 18px;
    border-radius: 50%;
    margin-top: 6px;
  }

  h5 {
    text-align: center;
    color: #565656;
    font-size: 24px;
    box-sizing: border-box;
    margin-bottom: 28px;
  }

  i {
    margin-left: 390px;
    color: #999999;
  }

  span {
    color: #999999;
    margin-left: 40px;
  }

  p {
    display: inline;
    color: #999999;
    margin-left: 40px;
  }
}
::v-deep .text {
  margin-top: 40px;
  padding-bottom: 60px;
  // background: brown;
  // height: 400px;
  div,
  span,
  applet,
  object,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: revert;
    padding: revert;
    border: revert;
    font: revert;
    vertical-align: revert;
    text-decoration: revert;
  }
  img {
    margin-top: 20px;
  }
}
.wrap {
  margin-bottom: 80px;
  margin-top: 55px;

  h5 {
    text-align: center;
    font-weight: 400;
    color: #606060;
    line-height: 28px;
    font-size: 20px;
  }
  p {
    line-height: 24px;
    margin-bottom: 12px;
    color: #333333;
    line-height: 24px;
    margin-bottom: 12px;
    font-weight: 400;
  }

  img {
    margin-top: 35px;
  }
}
</style>