import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/base.css'
import '@/assets/css/reset.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import Elementui from "element-ui"

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)


// import '@/assets/css/element-vWariables.scss'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import i18n from './utils/i18n/i18n'
import request from '@/utils/request'
Vue.prototype.$request = request
import pubNavLink from '@/utils/utils'
Vue.prototype.$pubNavLink = pubNavLink // 公用仿导航条转跳

Vue.prototype.$myJson = (json) => { // 解耦复制
    return !json ? {} : JSON.parse(JSON.stringify(json))
}

// import axios from 'request'
// Vue.prototype.$http = axios //全局注册，使用方法：this.$http
//全局挂载
Vue.use(Elementui, {
    locale
})

router.beforeEach((to, from, next) => {
    let lan = sessionStorage.getItem('myLan')
    if (to.meta.title) {
        if (lan == 'zh') {
            // if (to.path == '/service') {
            //     if (to.query.type == '4') {
            //         document.title = '交通信息';
            //     } else if (to.query.type == '3') {
            //         document.title = '展会地图';
            //     } else if (to.query.type == '2') {
            //         document.title = '展馆分布';
            //     } else if (to.query.type == '1') {
            //         document.title = '展品结构';
            //     }
            // } else if (to.path == '/zhibo') {
            //     if (to.query.type == '4') {
            //         document.title = '直播购物';
            //     } else if (to.query.type == '3') {
            //         document.title = '大咖讲茶';
            //     } else if (to.query.type == '2') {
            //         document.title = '云播茶事';
            //     } else if (to.query.type == '1') {
            //         document.title = '逛展导览';
            //     } else {
            //         document.title = '直播一条街';
            //     }
            // } else if (to.path == '/activity') {
            //     if (to.query.type == '1') {
            //         document.title = '推介活动';
            //     } else if (to.query.type == '2') {
            //         document.title = '重大活动';
            //     } else {
            //         document.title = '同期活动';
            //     }
            // } else if (to.path == '/tea') {
            //     if (to.query.type == '4') {
            //         document.title = '国际茶';
            //     } else if (to.query.type == '3') {
            //         document.title = '茶成就';
            //     } else if (to.query.type == '1') {
            //         document.title = '茶知识';
            //     } else if (to.query.type == '5') {
            //         document.title = '茶生活';
            //     } else if (to.query.type == '2') {
            //         document.title = '茶历史';
            //     } else {
            //         document.title = '茶文化';
            //     }
            // } else if (to.path == '/about') {
            //     if (to.query.type == '2') {
            //         document.title = '往届展会';
            //     } else if (to.query.type == '1') {
            //         document.title = '关于我们';
            //     } else {
            //         document.title = '关于我们';
            //     }
            // } else if (to.path == '/new') {
            //     if (to.query.type == '2') {
            //         document.title = '展会新闻';
            //     } else if (to.query.type == '3') {
            //         document.title = '视频新闻';
            //     } else if (to.query.type == '1') {
            //         document.title = '图片新闻';
            //     } else {
            //         document.title = '新闻管理';
            //     }
            // }
        }
        if (lan) {
            document.title = lan == 'zh' ? '中国国际茶叶博览会' : 'China International Tea Expo'
        } else {
            document.title = '中国国际茶叶博览会'
        }
    }
    if (lan != 'zh') { // 英文下特殊处理
        let title = document.title.toLocaleLowerCase()
        switch (title) {
            case 'zhibo':
                title = 'Live'
                break;
            case 'activity':
                title = 'Activities'
                break;
            default:
                break;
        }
        document.title = title
    }
    next()
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')